import { googleTagManager, LGTagManager } from '@lettucegrow/atoms.utils';

const {
  productDetail,
  trackBuyablePurchase,
  trackClick,
  trackCustomGAEvent,
  trackEnhancedAddToCart,
  trackEnhancedCheckout,
  trackEnhancedEcommerce,
  trackEnhancedPurchase,
  trackEnhancedRemoveFromCart,
  trackFarmstandPurchase,
  trackViewed,
} = googleTagManager;

export {
  productDetail,
  trackBuyablePurchase,
  trackClick,
  trackCustomGAEvent,
  trackEnhancedAddToCart,
  trackEnhancedCheckout,
  trackEnhancedEcommerce,
  trackEnhancedPurchase,
  trackEnhancedRemoveFromCart,
  trackFarmstandPurchase,
  trackViewed,
};

export default LGTagManager;
