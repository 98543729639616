import { patternUtils } from '@lettucegrow/atoms.utils';

import paths from 'constants/paths';
import shopCategories from 'constants/shopCategories';
import { KNOT_PLANT_BUNDLE_SKUS } from 'constants/sku';

export const formatPrice = patternUtils.formatPrice;

// if fixedOverwrite is defined the value will be used to format the decimal points
// otherwise we check if price is not whole dollar show cents (2 decimal points), otherwise just show whole dollar
export const formatDynamicDecimalPrice = (priceCents, hasDollarSign = true, fixedOverwrite) => {
  const fixed = fixedOverwrite ? fixedOverwrite : priceCents % 100 !== 0 ? 2 : 0;
  return formatPrice((priceCents / 100) * 1, fixed, hasDollarSign);
};

// used for analytics formatting in checkout events
export const formatProductsForDataLayer = (items) => {
  return items.map((item) => {
    return {
      name: item.name,
      id: item.sku,
      price: `${item.priceCents / 100}`,
      category: item.category,
      quantity: item.qty,
    };
  });
};

export const getItemPath = (item, customBundleSlug) => {
  if (KNOT_PLANT_BUNDLE_SKUS.includes(item?.sku)) {
    return `${paths.HARVEST_PLAN.KNOT}/${item?.plantCount}`;
  } else if (item?.category === shopCategories.SEEDLINGS) {
    return `${paths.SEEDLINGS}/${item?.slug}`;
  } else if (item?.category === shopCategories.BUNDLES) {
    return `${paths.BUNDLES}/${item?.slug}`;
  } else if (item?.category === shopCategories.CUSTOM_BUNDLES) {
    return `${paths.BUNDLES}/${customBundleSlug}`;
  } else if (item?.category === shopCategories.FARMSTAND) {
    return paths.FARMSTAND;
  }
  return `${paths.SHOP_PRODUCTS}/${item?.slug}`;
};

// HEADS UP - Utilites shared by cart/checkout/order confirmation. These CANNOT be redux reducers because of order conf use
export const getVouchersCount = (vouchers) => vouchers?.reduce((sum, voucher) => (sum += voucher.countUsed), 0) || 0;
export const getVouchersDiscount = (vouchers) => vouchers?.reduce((priceCents, voucher) => (priceCents += voucher.discountCents), 0) || 0;
export const getSeedlingVoucherUses = (items, vouchers) => {
  const seedlingItems = items.filter((item) => item.category === shopCategories.SEEDLINGS);
  let uses = [];
  seedlingItems.forEach((seedling) => {
    const usedVouchers = vouchers[seedling.sku];
    if (usedVouchers?.length) {
      uses = uses.concat(usedVouchers);
    }
  });
  return uses;
};

export const getVoucherUsesCountByItemSku = (vouchers, itemSku) => getVouchersCount(vouchers[itemSku]);
export const getVoucherUsesDiscountCentsByItemSku = (vouchers, itemSku) => getVouchersDiscount(vouchers[itemSku]);
export const getSeedlingVoucherUsesCount = (items, vouchers) => getVouchersCount(getSeedlingVoucherUses(items, vouchers));
export const getSeedlingVoucherUsesDiscountCents = (items, vouchers) => getVouchersDiscount(getSeedlingVoucherUses(items, vouchers));
export const normalizeVoucherUses = (vouchers) =>
  vouchers.reduce((result, voucherUse) => {
    /* HEADS UP - valid case for multiple voucherUses per item, and valid case for not all qty of an item covered by voucherUses */
    if (result[voucherUse.buyableSku]?.length) {
      result[voucherUse.buyableSku].push(voucherUse);
    } else {
      result[voucherUse.buyableSku] = [voucherUse];
    }
    return result;
  }, {});
